<template>
    <div>
        <div class="header">
            <span class="header_txt">提现审核</span>
        </div>
        <div class="header_box">
            <Tabs type="card"  @on-click="handleClick">
                <TabPane v-for="(item, index) in tabList" :key="index" :label="item.label" :name="item.name">
                    <!-- 内容 -->
                    <div>
                        <Table :columns="liveTable" :data="dataTable" border>
                            <template slot-scope="{row}" slot="customer">
                                <span>{{row.customer.nickname}}</span>
                            </template>
                            <template slot-scope="{row}" slot="reason">
                                <span v-if="row.reason">{{row.reason}}</span>
                                <span v-else>暂无</span>
                            </template>
                            <template slot-scope="{row}" slot="operation">
                                <div v-if="row.status == '待审核'" style="color: #409eff;cursor: pointer;" @click="process(row)">{{row.status}}</div>
                                <span v-else>已{{row.status}}</span>
                            </template>
                        </Table>
                    </div>
                </TabPane>
            </Tabs>
        </div>
        <!-- <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div> -->
        <!-- 审核弹框s -->
        <Modal v-model="processModal" title="审核" width="30%">
            <div style="display:flex;align-items:center;justify-content:space-around;height: 100px;">
                <span>原因</span>
                <Input type="text" v-model="processData.reason" style="width: 300px;" placeholder="输入拒绝或同意的原因"></Input>
            </div>
            <div slot="footer" style="display:flex;">
                <Button style="width: 50%;" @click="processModalF">拒绝</Button>
                <Button style="width: 50%;" type="primary" @click="processModalT">同意</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { withWithDraw, withWithList, withWithDeal } from '@/api/index'
    export default {
        data() {
            return {
                processModal:false,
                tabList: [
                    {
                        label: '待审核',
                        name: '0'
                    },
                    {
                        label: '已审核',
                        name: '1'
                    },
                ],
                liveTable: [
                    {title: '姓名',slot: 'customer',align:'center'},
                    {title: '审核状态',key: 'status',align:'center'},
                    {title: '原因',slot: 'reason',align:'center'},
                    {title: '申请时间',key: 'created_at',align:'center'},
                    {title: '操作',slot: 'operation',align:'center'},
                ],
                dataTable: [],
                processData: {
                    id: 3,
                    status: 2,
                    reason: ''
                }
                //page
                // total: 100,
                // storePrams: {
                //     page: 1,
                //     limit: 30,
                // },
            }
        },
        mounted(){
            // 初始化
            this.withWithDraw()
        },
        methods: {
            handleClick(name, event){
                if(name == 0){
                    console.log('待审核')
                    this.withWithDraw()
                }else{
                    console.log('已审核')
                    this.withWithList()
                }
            },
            // 审核弹框
            process(e){
                this.processModal = true
                this.processData.id = e.id
            },
            processModalF(){
                this.processModal = false
                this.processData.status = 2
                withWithDeal(this.processData).then(res => {
                    this.$Message.success(res.msg);
                    this.withWithDraw()
                })
            },
            processModalT(){
                this.processModal = false
                this.processData.status = 1
                withWithDeal(this.processData).then(res => {
                    this.$Message.success(res.msg);
                    this.withWithDraw()
                })
            },
            // 待审核列表
            withWithDraw(){
                withWithDraw().then(res => {
                    console.log('待审核列表',res.data)
                    this.dataTable = res.data
                })
            },
            // 已审核列表
            withWithList(){
                withWithList().then(res => {
                    console.log('已审核列表',res.data)
                    this.dataTable = res.data
                })
            },
            // 分页
            // pageChange(index) {
            //     this.storePrams.page = index;
            // },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    margin: 10px 0;
    padding: 20px 20px 20px 0;
    border-radius: 10px;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
  background: #fff !important;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
  color: #909395 !important;
}
</style>